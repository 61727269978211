import React from "react";
import { Link } from "react-router-dom";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import Timestamp from "../../common/components/timestamp";
import {
  AgentReferenceFragment,
  ListingFragment,
  ListingPublicVisibility,
} from "../../graphql/generated";

const ListingSummary: React.FC<{ listing?: ListingFragment }> = ({
  listing,
}) => {
  return (
    <Panel>
      <Panel.Body summary>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
          <DescriptionListItem title="ID" value={listing?.id} />
          <DescriptionListItem title="Mls ID" value={listing?.mlsId} />
          <DescriptionListItem
            title="External ID"
            value={listing?.externalId}
          />
          <DescriptionListItem
            title="Status"
            value={`${listing?.cardStatusShort} · ${listing?.cardStatusLong}`}
          />
          <DescriptionListItem
            title="Status Category"
            value={`${listing?.cardStatusCategoryShort} · ${listing?.cardStatusCategoryLong}`}
          />
          <DescriptionListItem
            title="Property Type"
            value={`${listing?.cardPropertyTypeShort} · ${listing?.cardPropertyTypeLong}`}
          />
          <DescriptionListContainer title="Created At">
            <Timestamp timestamp={listing?.createdAt} format="short" popover />
          </DescriptionListContainer>
          <DescriptionListContainer title="Modified At">
            <Timestamp timestamp={listing?.modifiedAt} format="short" popover />
          </DescriptionListContainer>
          <DescriptionListContainer title="MLS Modified At">
            <Timestamp
              timestamp={listing?.sourceModifiedAt}
              format="short"
              popover
            />
          </DescriptionListContainer>
          <DescriptionListContainer title="Price">
            {listing?.price
              ? listing.price.toLocaleString(undefined, {
                  currency: "USD",
                  style: "currency",
                })
              : "$—"}
          </DescriptionListContainer>
          <DescriptionListContainer title="Last list price">
            {listing?.lastListPrice ? (
              <>
                {listing.lastListPrice.toLocaleString(undefined, {
                  currency: "USD",
                  style: "currency",
                })}{" "}
                at{" "}
                <Timestamp
                  timestamp={listing?.priceChangedAt}
                  format="short"
                  popover
                />
              </>
            ) : (
              "—"
            )}
          </DescriptionListContainer>
          <DescriptionListItem
            title="Chicklets/Tiles"
            value={listing?.cardChicklets
              .map((item) => item.join(" "))
              .join(" · ")}
            span={3}
          />
          <DescriptionListContainer title="List agents">
            <ListingAgent agent={listing?.listAgent} /> /{" "}
            <ListingAgent agent={listing?.coListAgent} />
          </DescriptionListContainer>
          <DescriptionListContainer title="Buyer agents">
            <ListingAgent agent={listing?.buyerAgent} /> /{" "}
            <ListingAgent agent={listing?.coBuyerAgent} />
          </DescriptionListContainer>
          <DescriptionListContainer title="Public visibility">
            {listing?.publicVisibility === ListingPublicVisibility.Public && (
              <abbr title="Listing can be seen publicly">Public</abbr>
            )}
            {listing?.publicVisibility ===
              ListingPublicVisibility.PublicNoAddress && (
              <abbr title="Listing can be seen publicly, but the address can not be displayed">
                Public (no address)
              </abbr>
            )}
            {listing?.publicVisibility ===
              ListingPublicVisibility.Restricted && (
              <abbr title="The listing is not allowed to be viewed publicly">
                Restricted
              </abbr>
            )}
          </DescriptionListContainer>
        </dl>
      </Panel.Body>
    </Panel>
  );
};

const ListingAgent: React.FC<{
  agent?: AgentReferenceFragment | null;
}> = ({ agent }) => {
  if (!agent) {
    return <>—</>;
  }
  if (agent && !agent.agent) {
    return <>{agent.id}</>;
  }
  return (
    <Link to={`/agents/${agent?.id}`} className="text-blue-900 hover:underline">
      {agent?.agent?.name}
    </Link>
  );
};

export default ListingSummary;
