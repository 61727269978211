import { Menu, Transition } from "@headlessui/react";
import {
  CloudArrowDownIcon,
  CurrencyDollarIcon,
  EllipsisVerticalIcon,
  GlobeAmericasIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { classNames } from "../../common/utils/classnames";
import {
  IngestTaskTarget,
  ListingFragment,
  useCreateIngestTaskForPrimaryKeyMutation,
  useRemoveListingLastListPriceMutation,
  useReprocessListingHistoryMutation,
} from "../../graphql/generated";
import { NotificationContext } from "../../common/context/notification";

const ListingActions: React.FC<{ listing?: ListingFragment }> = ({
  listing,
}) => {
  const { notifier } = React.useContext(NotificationContext);
  const [
    { fetching: createIngestTaskForPrimaryKeyFetching },
    createIngestTaskForPrimaryKeyMutation,
  ] = useCreateIngestTaskForPrimaryKeyMutation();
  const navigate = useNavigate();
  const createIngestTaskForPrimaryKey = React.useCallback(async () => {
    if (createIngestTaskForPrimaryKeyFetching) {
      return;
    }
    const { data, error } = await createIngestTaskForPrimaryKeyMutation({
      id: listing?.id ?? "",
      mlsId: listing?.mls?.id ?? "",
      target: IngestTaskTarget.Property,
    }).then(notifier.notifyGraphql(`Created ingest task for ${listing?.id}`));
    if (!error) {
      navigate(`/ingest_tasks/${data?.createIngestTaskForPrimaryKey.id}`);
    }
  }, [
    createIngestTaskForPrimaryKeyFetching,
    createIngestTaskForPrimaryKeyMutation,
    navigate,
    listing,
  ]);
  const [, reprocessListingHistoryMutation] =
    useReprocessListingHistoryMutation();

  const reprocessListingHistory = React.useCallback(async () => {
    await reprocessListingHistoryMutation({
      id: listing?.id ?? "",
    }).then(notifier.notifyGraphql("Reprocess Task Submitted"));
  }, [listing?.id, reprocessListingHistoryMutation]);

  const [, removeLastListPriceMutation] =
    useRemoveListingLastListPriceMutation();

  const removeLastListPrice = React.useCallback(async () => {
    await removeLastListPriceMutation({
      id: listing?.id ?? "",
    }).then(notifier.notifyGraphql("Last list price removed"));
  }, [listing?.id]);

  return (
    <Menu as="div" className="relative inline-block text-left mt-4 sm:mt-0">
      <div>
        <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={`/listings/${listing?.id}/geocode`}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm cursor-pointer"
                  )}
                >
                  <GlobeAmericasIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Update Geocoding
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={createIngestTaskForPrimaryKey}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm cursor-pointer"
                  )}
                >
                  <CloudArrowDownIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Re-ingest Listing from MLS
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={reprocessListingHistory}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm cursor-pointer"
                  )}
                >
                  <CloudArrowDownIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Reprocess Listing History
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={removeLastListPrice}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "group flex items-center px-4 py-2 text-sm cursor-pointer"
                  )}
                >
                  <CurrencyDollarIcon
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Remove last list price
                </div>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ListingActions;
