import { Menu, Transition } from "@headlessui/react";
import React from "react";
import { useParams } from "react-router-dom";
import Error from "../../common/components/error";
import Loading from "../../common/components/loading";
import {
  MlsFragment,
  PropertyDetailsRichTableFragment,
  PropertyDetailsRichTableId,
  PropertyDetailsRichTableInput,
  PropertyDetailsRichTableRowFragment,
  PropertyDetailsRichTableRowInput,
  PropertyDetailsRowAction,
  PropertyDetailsTableFragment,
  PropertyDetailsTableId,
  PropertyDetailsTableInput,
  PropertyDetailsTableRowFragment,
  PropertyDetailsTableRowInput,
  PropertyDetailsTableStyle,
  useCreatePropertyDetailsMutation,
  useCreatePropertyDetailsRichTableRowMutation,
  useCreatePropertyDetailsTableRowMutation,
  useMlsQuery,
  useMovePropertyDetailsRowMutation,
  usePropertyDetailsQuery,
  useRemovePropertyDetailsRowMutation,
  useUpdatePropertyDetailsRichTableMutation,
  useUpdatePropertyDetailsRichTableRowMutation,
  useUpdatePropertyDetailsTableMutation,
  useUpdatePropertyDetailsTableRowMutation,
} from "../../graphql/generated";
import MlsTitle from "../components/title";
import { useTitle } from "../../common/utils/title";
import Panel from "../../common/components/panel";
import { ZenDialog, ZenDialogState } from "../../common/components/zen-dialog";
import {
  ChevronUpDownIcon,
  EllipsisVerticalIcon,
  EyeSlashIcon,
  MinusCircleIcon,
  PencilIcon,
  PlusCircleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import CommonSwitch from "../../common/components/switch";
import LoadingIcon from "../../common/components/loadingicon";
import { NotificationContext } from "../../common/context/notification";
import { classNames } from "../../common/utils/classnames";
import {
  DragDropContext,
  Draggable,
  DraggingStyle,
  Droppable,
  DropResult,
  NotDraggingStyle,
} from "react-beautiful-dnd";

function randomId(): string {
  if (window && window.crypto) {
    return window.crypto.randomUUID();
  } else {
    return new Date().toISOString();
  }
}

const PropertyDetailsEditShow: React.FC = () => {
  const params = useParams();
  const [{ data: mlsData }] = useMlsQuery({
    variables: { id: params.mlsId ?? "" },
    pause: !params.mlsId,
  });
  const [{ data, error }, refetch] = usePropertyDetailsQuery({
    variables: { id: params.mlsId ?? "" },
    pause: !params.mlsId,
  });
  useTitle(
    "Property details",
    data?.propertyDetails?.mls.shortName ?? params.mlsId,
    "MLSes"
  );

  if (
    error?.graphQLErrors.some((err) => err.extensions["type"] == "NotFound") &&
    mlsData?.mls
  ) {
    return (
      <PropertyDetailsCreate mls={mlsData.mls} onCreated={() => refetch()} />
    );
  }

  return (
    <>
      <Loading show={!data && !error} />
      <Error error={error} />
      <Transition
        show={!!data}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
      >
        {data && (
          <div className="max-w-7xl mx-auto py-4">
            <MlsTitle mls={data?.propertyDetails?.mls} />
            {data.propertyDetails.property.tables?.map((table) => (
              <PropertyDetailsTable
                key={table.id}
                id={params.mlsId ?? ""}
                table={table}
              />
            ))}
            <PropertyDetailsRichTable
              id={params.mlsId ?? ""}
              richTable={data.propertyDetails.room}
            />
            <PropertyDetailsRichTable
              id={params.mlsId ?? ""}
              richTable={data.propertyDetails.unitType}
            />
          </div>
        )}
      </Transition>
    </>
  );
};

const PropertyDetailsCreate: React.FC<{
  mls: MlsFragment;
  onCreated: () => void;
}> = ({ mls, onCreated }) => {
  const { notifier } = React.useContext(NotificationContext);
  const [{ fetching }, mutation] = useCreatePropertyDetailsMutation();

  const callback = React.useCallback(async () => {
    const { data } = await mutation({
      id: mls.id,
    }).then(notifier.notifyGraphql("Property details created"));
    if (data?.createPropertyDetails) {
      onCreated();
    }
  }, [mls, onCreated]);

  return (
    <div className="max-w-7xl mx-auto py-4">
      <MlsTitle mls={mls} />
      <Panel>
        <Panel.Title>Not found</Panel.Title>
        <Panel.Body>
          <div className="space-y-4">
            <p>
              Property details for this MLS were not found. Would you like to
              create them?
            </p>
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:w-auto sm:text-sm bg-indigo-600 hover:bg-indigo-700 text-white"
              disabled={fetching}
              onClick={() => callback()}
            >
              {fetching && <LoadingIcon />}
              Create property details
            </button>
          </div>
        </Panel.Body>
      </Panel>
    </div>
  );
};

const PropertyDetailsTable: React.FC<{
  id: string;
  table: PropertyDetailsTableFragment;
}> = ({ id, table }) => {
  const { notifier } = React.useContext(NotificationContext);
  const [, addRowMutation] = useCreatePropertyDetailsTableRowMutation();
  const [{ fetching: moveRowFetching }, moveRowMutation] =
    useMovePropertyDetailsRowMutation();
  const [{ fetching: updateFetching }, updateMutation] =
    useUpdatePropertyDetailsTableMutation();
  const [isReordering, setIsReordering] = React.useState(false);
  const [isUpdateDialogVisible, setIsUpdateDialogVisible] =
    React.useState(false);

  const update = React.useCallback(
    async (update: PropertyDetailsTableInput) => {
      const { data } = await updateMutation({
        id,
        tableId: table.tableId,
        update,
      }).then(notifier.notifyGraphql("Table updated"));
      if (data) {
        setIsUpdateDialogVisible(false);
      }
    },
    [id, table]
  );

  const addRow = React.useCallback(async () => {
    addRowMutation({
      id,
      tableId: table.tableId,
      data: {},
    }).then(notifier.notifyGraphql("Row added"));
  }, [id, table]);

  const moveRow = React.useCallback(
    async (
      row:
        | PropertyDetailsTableRowFragment
        | PropertyDetailsRichTableRowFragment,
      destination: number
    ) => {
      moveRowMutation({
        id,
        rowId: row.id,
        destination,
      }).then(notifier.notifyGraphql("Row moved"));
    },
    [id]
  );

  let path;
  switch (table.tableId) {
    case PropertyDetailsTableId.KeyDetails:
      path = "Key details";
      break;
    case PropertyDetailsTableId.AgentInfoLegalFinancialTax:
      path = "Agent info > legal/financial/tax";
      break;
    case PropertyDetailsTableId.AgentInfoListingBroker:
      path = "Agent info > listing broker";
      break;
    case PropertyDetailsTableId.AgentInfoListingInfo:
      path = "Agent info > listing info";
      break;
    case PropertyDetailsTableId.AgentInfoOwnerInfo:
      path = "Agent info > owner info";
      break;
    case PropertyDetailsTableId.FeaturesAndAmenitiesBuilding:
      path = "Features and amenities > building";
      break;
    case PropertyDetailsTableId.FeaturesAndAmenitiesExtra:
      path = "Features and amenities > extra";
      break;
    case PropertyDetailsTableId.FeaturesAndAmenitiesFeatures:
      path = "Features and amenities > features";
      break;
    case PropertyDetailsTableId.FeaturesAndAmenitiesFinancialInfo:
      path = "Features and amenities > financial info";
      break;
    case PropertyDetailsTableId.FeaturesAndAmenitiesMulti:
      path = "Features and amenities > multi";
      break;
    case PropertyDetailsTableId.FeaturesAndAmenitiesParkingDetails:
      path = "Features and amenities > parking details";
      break;
  }
  return (
    <>
      <Panel>
        <Panel.Title
          actions={
            <AddReorderActions
              onAddRow={addRow}
              onReorder={() => setIsReordering(!isReordering)}
              reordering={isReordering}
            />
          }
        >
          {table.title}{" "}
          <EditButton onClick={() => setIsUpdateDialogVisible(true)} />{" "}
          <span className="text-xs text-gray-400">{path}</span>
        </Panel.Title>
        <Panel.Body>
          {isReordering ? (
            <PropertyDetailsReorderList
              rows={table.rows}
              fetching={moveRowFetching}
              onMove={moveRow}
              onReorderingFinished={() => setIsReordering(false)}
            />
          ) : (
            <div className="space-y-4">
              {table.rows.map((row) => (
                <PropertyDetailsTableRow key={row.id} id={id} row={row} />
              ))}
            </div>
          )}
        </Panel.Body>
      </Panel>
      <PropertyDetailsTableUpdateDialog
        isFetching={updateFetching}
        onCancel={() => setIsUpdateDialogVisible(false)}
        onSubmit={update}
        show={isUpdateDialogVisible}
        table={table}
      />
    </>
  );
};

const PropertyDetailsRichTable: React.FC<{
  id: string;
  richTable: PropertyDetailsRichTableFragment;
}> = ({ id, richTable }) => {
  const { notifier } = React.useContext(NotificationContext);
  const [, addRowMutation] = useCreatePropertyDetailsRichTableRowMutation();
  const [{ fetching: moveRowFetching }, moveRowMutation] =
    useMovePropertyDetailsRowMutation();
  const [{ fetching: updateFetching }, updateMutation] =
    useUpdatePropertyDetailsRichTableMutation();
  const [isReordering, setIsReordering] = React.useState(false);
  const [isUpdateDialogVisible, setIsUpdateDialogVisible] =
    React.useState(false);

  const update = React.useCallback(
    async (update: PropertyDetailsRichTableInput) => {
      const { data } = await updateMutation({
        id,
        richTableId: richTable.richTableId,
        update,
      }).then(notifier.notifyGraphql("Rich table updated"));
      if (data) {
        setIsUpdateDialogVisible(false);
      }
    },
    [id, richTable]
  );

  const addRow = React.useCallback(async () => {
    addRowMutation({
      id,
      richTableId: richTable.richTableId,
      data: {},
    }).then(notifier.notifyGraphql("Row added"));
  }, [id, richTable]);

  const moveRow = React.useCallback(
    async (
      row:
        | PropertyDetailsTableRowFragment
        | PropertyDetailsRichTableRowFragment,
      destination: number
    ) => {
      moveRowMutation({
        id,
        rowId: row.id,
        destination,
      }).then(notifier.notifyGraphql("Row moved"));
    },
    [id]
  );

  let name;
  switch (richTable.richTableId) {
    case PropertyDetailsRichTableId.Room:
      name = "Room";
      break;
    case PropertyDetailsRichTableId.UnitType:
      name = "Unit Type";
      break;
  }

  return (
    <>
      <Panel>
        <Panel.Title
          actions={
            <AddReorderActions
              onAddRow={() => addRow()}
              onReorder={() => setIsReordering(!isReordering)}
              reordering={isReordering}
            />
          }
        >
          {name}{" "}
          <span className="text-xs text-gray-400">sub-resource section</span>
        </Panel.Title>
        <Panel.Body>
          <div>
            <span className="font-mono">{richTable.sectionHeader}</span>{" "}
            <EditButton onClick={() => setIsUpdateDialogVisible(true)} />
          </div>
          {richTable.sectionSubheader && (
            <div className="font-mono text-xs text-gray-400">
              {richTable.sectionSubheader}
            </div>
          )}
        </Panel.Body>
        <Panel.Body>
          <div>
            <span className="font-mono">{richTable.title}</span>{" "}
            <EditButton onClick={() => setIsUpdateDialogVisible(true)} />
          </div>
          {richTable.titleExtras.map((extra, idx) => (
            <div
              key={`${extra}:${idx}`}
              className="font-mono text-xs text-gray-400"
            >
              {extra}
            </div>
          ))}
        </Panel.Body>
        <Panel.Body>
          {isReordering ? (
            <PropertyDetailsReorderList
              rows={richTable.rows}
              fetching={moveRowFetching}
              onMove={moveRow}
              onReorderingFinished={() => setIsReordering(false)}
            />
          ) : (
            <div className="space-y-4">
              {richTable.rows.map((row) => (
                <PropertyDetailsRichTableRow key={row.id} id={id} row={row} />
              ))}
            </div>
          )}
        </Panel.Body>
      </Panel>
      <PropertyDetailsRichTableUpdateDialog
        isFetching={updateFetching}
        onCancel={() => setIsUpdateDialogVisible(false)}
        onSubmit={update}
        richTable={richTable}
        show={isUpdateDialogVisible}
      />
    </>
  );
};

const PropertyDetailsReorderRow: React.FC<{
  row: PropertyDetailsTableRowFragment | PropertyDetailsRichTableRowFragment;
}> = ({ row }) => {
  return (
    <div key={row.id} className="flex space-x-1">
      <div className="flex-none">
        <ChevronUpDownIcon className="w-4 h-4 inline rounded-full bg-gray-200" />{" "}
      </div>
      <div className="flex-1">
        <div>
          <PropertyDetailsRowLabel row={row} />
        </div>
        <div className="font-mono text-xs text-gray-400">{row.value}</div>
      </div>
    </div>
  );
};

const PropertyDetailsReorderList: React.FC<{
  rows: (
    | PropertyDetailsTableRowFragment
    | PropertyDetailsRichTableRowFragment
  )[];
  fetching?: boolean;
  onMove?: (
    row: PropertyDetailsTableRowFragment | PropertyDetailsRichTableRowFragment,
    idx: number
  ) => void;
  onReorderingFinished?: () => void;
}> = ({ rows, fetching, onMove, onReorderingFinished }) => {
  const onDragEnd = React.useCallback(
    async (result: DropResult) => {
      console.log(result);
      // Current update happening
      if (fetching) {
        return;
      }

      // dropped outside the list
      if (!result.destination) {
        return;
      }

      // Moved to the same place
      if (result.source.index == result.destination.index) {
        return;
      }

      const row = rows[result.source.index];
      onMove && onMove(row, result.destination.index);
    },
    [fetching, rows]
  );

  function getDraggableStyle(
    isDragging: boolean,
    style: DraggingStyle | NotDraggingStyle | undefined
  ): React.CSSProperties {
    if (isDragging) {
      return {
        background: "rgba(255, 255, 255, 0.8)",
        ...style,
      };
    } else {
      return {
        ...style,
      };
    }
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="rows">
          {(provided) => (
            <div
              className={classNames("space-y-4", fetching ? "opacity-25" : "")}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {rows.map((row, idx) => {
                return (
                  <Draggable
                    key={row.id}
                    draggableId={row.id}
                    index={idx}
                    isDragDisabled={fetching}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getDraggableStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <PropertyDetailsReorderRow row={row} />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <button
        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-4 sm:w-auto sm:text-sm"
        onClick={() => onReorderingFinished && onReorderingFinished()}
      >
        Done
      </button>
    </>
  );
};

const PropertyDetailsTableRow: React.FC<{
  id: string;
  row: PropertyDetailsTableRowFragment;
}> = ({ id, row }) => {
  const { notifier } = React.useContext(NotificationContext);
  const [isUpdateDialogVisible, setIsUpdateDialogVisible] =
    React.useState(false);
  const [isRemoveDialogVisible, setIsRemoveDialogVisible] =
    React.useState(false);

  const [{ fetching: editFetching }, editMutation] =
    useUpdatePropertyDetailsTableRowMutation();
  const [{ fetching: removeFetching }, removeMutation] =
    useRemovePropertyDetailsRowMutation();

  const updateRow = React.useCallback(
    async (update: PropertyDetailsTableRowInput) => {
      await editMutation({
        id,
        rowId: row.id,
        update,
      }).then(notifier.notifyGraphql("Row updated"));
      setIsUpdateDialogVisible(false);
    },
    [id, row.id]
  );

  const removeRow = React.useCallback(async () => {
    await removeMutation({
      id,
      rowId: row.id,
    }).then(notifier.notifyGraphql("Row removed"));
  }, [id, row.id]);

  return (
    <div>
      <div>
        <PropertyDetailsRowLabel row={row} />{" "}
        <EditButton onClick={() => setIsUpdateDialogVisible(true)} />{" "}
        <TrashButton onClick={() => setIsRemoveDialogVisible(true)} />
      </div>
      <div className="font-mono text-xs text-gray-400">{row.value}</div>
      <PropertyDetailsRowUpdateDialog
        row={row}
        show={isUpdateDialogVisible}
        isFetching={editFetching}
        onCancel={() => setIsUpdateDialogVisible(false)}
        onSubmit={updateRow}
      />
      <PropertyDetailsRowRemoveDialog
        show={isRemoveDialogVisible}
        isFetching={removeFetching}
        onCancel={() => setIsRemoveDialogVisible(false)}
        onSubmit={removeRow}
      />
    </div>
  );
};

const PropertyDetailsRowUpdateDialog: React.FC<{
  row: PropertyDetailsTableRowFragment;
  show: boolean;
  isFetching: boolean;
  onCancel: () => void;
  onSubmit: (update: PropertyDetailsTableRowInput) => void;
}> = ({ row, show, isFetching, onCancel, onSubmit }) => {
  const initialFocus = React.useRef(null);
  const labelId = React.useId();
  const [label, setLabel] = React.useState(row.label);
  const valueId = React.useId();
  const [value, setValue] = React.useState(row.value);
  const [actionType, setActionType] = React.useState(row.actionType);
  const [actionTemplate, setActionTemplate] = React.useState(
    () => row.actionTemplate ?? ""
  );
  const [matches, setMatches] = React.useState(() =>
    row.matches.map((match) => {
      return { id: match.id, key: match.key, value: match.value };
    })
  );
  const addMatchAt = React.useCallback(
    (index: number) => {
      const newMatch = {
        id: randomId(),
        key: "",
        value: "",
      };
      const newMatches = [
        ...matches.slice(0, index),
        newMatch,
        ...matches.slice(index),
      ];
      setMatches(newMatches);
    },
    [matches]
  );
  const removeMatch = React.useCallback(
    (id: string) => {
      const newMatches = matches.filter((match) => match.id != id);
      setMatches(newMatches);
    },
    [matches]
  );
  const setMatchKey = React.useCallback(
    (id: string, key: string) => {
      const newMatches = [...matches];
      const found = newMatches.find((val) => val.id == id);
      if (found) {
        found.key = key;
      }
      setMatches(newMatches);
    },
    [matches]
  );
  const setMatchValue = React.useCallback(
    (id: string, value: string) => {
      const newMatches = [...matches];
      const found = newMatches.find((val) => val.id == id);
      if (found) {
        found.value = value;
      }
      setMatches(newMatches);
    },
    [matches]
  );
  const hideIfEmptyId = React.useId();
  const [hideIfEmpty, setHideIfEmpty] = React.useState(row.hideIfEmpty);
  const hiddenId = React.useId();
  const [hidden, setHidden] = React.useState(row.hidden);
  const agentOnlyId = React.useId();
  const [agentOnly, setAgentOnly] = React.useState(row.agentOnly);

  return (
    <ZenDialog
      icon={PencilIcon}
      onCancel={() => onCancel()}
      onSubmit={() =>
        onSubmit({
          label,
          value,
          agentOnly,
          hidden,
          hideIfEmpty,
          action: {
            action: actionType,
            value:
              actionType === PropertyDetailsRowAction.None
                ? undefined
                : actionTemplate,
          },
          matches: matches.map((match) => {
            return { key: match.key, value: match.value };
          }),
        })
      }
      submit="Update"
      title="Update row"
      show={show}
      state={isFetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
      initialFocus={initialFocus}
    >
      <div className="space-y-2">
        <div>
          <div>
            <label htmlFor={labelId}>Label</label>
          </div>
          <div>
            <input
              id={labelId}
              type="text"
              className="w-full"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
          </div>
        </div>
        <div>
          <div>
            <label htmlFor={valueId}>Value</label>
          </div>
          <div>
            <input
              id={valueId}
              ref={initialFocus}
              type="text"
              className="w-full"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
        </div>
        <div>
          <div>
            <label>Action</label>
          </div>
          <div>
            <label>
              <input
                type="radio"
                checked={actionType === PropertyDetailsRowAction.None}
                onChange={() => setActionType(PropertyDetailsRowAction.None)}
              />{" "}
              None
            </label>{" "}
            <label>
              <input
                type="radio"
                checked={actionType === PropertyDetailsRowAction.MailTo}
                onChange={() => setActionType(PropertyDetailsRowAction.MailTo)}
              />{" "}
              MailTo
            </label>{" "}
            <label>
              <input
                type="radio"
                checked={actionType === PropertyDetailsRowAction.Tel}
                onChange={() => setActionType(PropertyDetailsRowAction.Tel)}
              />{" "}
              Tel
            </label>{" "}
            <label>
              <input
                type="radio"
                checked={actionType === PropertyDetailsRowAction.Url}
                onChange={() => setActionType(PropertyDetailsRowAction.Url)}
              />{" "}
              Url
            </label>
          </div>
          <div>
            <input
              type="text"
              className="w-full"
              value={actionTemplate}
              disabled={actionType === PropertyDetailsRowAction.None}
              onChange={(e) => setActionTemplate(e.target.value)}
            />
          </div>
        </div>
        <div>
          <div>
            <label>Matches</label>
          </div>
          <div>
            {matches.length ? (
              matches.map((match, idx) => (
                <div key={match.id}>
                  <input
                    type="text"
                    value={match.key}
                    onChange={(e) => setMatchKey(match.id, e.target.value)}
                  />{" "}
                  <input
                    type="text"
                    value={match.value}
                    onChange={(e) => setMatchValue(match.id, e.target.value)}
                  />
                  <button onClick={() => addMatchAt(idx + 1)}>
                    <PlusCircleIcon className="w-4 h-4 text-gray-400" />
                  </button>
                  <button onClick={() => removeMatch(match.id)}>
                    <MinusCircleIcon className="w-4 h-4 text-gray-400" />
                  </button>
                </div>
              ))
            ) : (
              <div>
                <span className="italic test-gray-400">No matches</span>{" "}
                <button onClick={() => addMatchAt(0)}>
                  <PlusCircleIcon className="w-4 h-4 text-gray-400" />
                </button>
              </div>
            )}
          </div>
        </div>
        <div>
          <div>
            <label htmlFor={hideIfEmptyId}>Hide if empty</label>
          </div>
          <div>
            <CommonSwitch
              enabled={hideIfEmpty}
              label="Hide if empty"
              toggle={() => setHideIfEmpty(!hideIfEmpty)}
            />
          </div>
        </div>
        <div>
          <div>
            <label htmlFor={agentOnlyId}>Agent only</label>
          </div>
          <div>
            <CommonSwitch
              enabled={agentOnly}
              label="Agent only"
              toggle={() => setAgentOnly(!agentOnly)}
            />
          </div>
        </div>
        <div>
          <div>
            <label htmlFor={hiddenId}>Hidden</label>
          </div>
          <div>
            <CommonSwitch
              enabled={hidden}
              label="Hidden"
              toggle={() => setHidden(!hidden)}
            />
          </div>
        </div>
      </div>
    </ZenDialog>
  );
};

const PropertyDetailsRichTableRow: React.FC<{
  id: string;
  row: PropertyDetailsRichTableRowFragment;
}> = ({ id, row }) => {
  const { notifier } = React.useContext(NotificationContext);
  const [isUpdateDialogVisible, setIsUpdateDialogVisible] =
    React.useState(false);
  const [isRemoveDialogVisible, setIsRemoveDialogVisible] =
    React.useState(false);

  const [{ fetching: editFetching }, editMutation] =
    useUpdatePropertyDetailsRichTableRowMutation();
  const [{ fetching: removeFetching }, removeMutation] =
    useRemovePropertyDetailsRowMutation();

  const updateRow = React.useCallback(
    async (update: PropertyDetailsRichTableRowInput) => {
      await editMutation({
        id,
        rowId: row.id,
        update,
      }).then(notifier.notifyGraphql("Row updated"));
      setIsUpdateDialogVisible(false);
    },
    [id, row.id]
  );

  const removeRow = React.useCallback(async () => {
    await removeMutation({
      id,
      rowId: row.id,
    }).then(notifier.notifyGraphql("Row removed"));
  }, [id, row.id]);

  return (
    <div>
      <div>
        <PropertyDetailsRowLabel row={row} />{" "}
        <EditButton onClick={() => setIsUpdateDialogVisible(true)} />{" "}
        <TrashButton onClick={() => setIsRemoveDialogVisible(true)} />
      </div>
      <div className="font-mono text-xs text-gray-400">{row.value}</div>
      <PropertyDetailsRichTableRowUpdateDialog
        row={row}
        show={isUpdateDialogVisible}
        isFetching={editFetching}
        onCancel={() => setIsUpdateDialogVisible(false)}
        onSubmit={updateRow}
      />
      <PropertyDetailsRowRemoveDialog
        show={isRemoveDialogVisible}
        isFetching={removeFetching}
        onCancel={() => setIsRemoveDialogVisible(false)}
        onSubmit={removeRow}
      />
    </div>
  );
};

const PropertyDetailsRichTableRowUpdateDialog: React.FC<{
  row: PropertyDetailsRichTableRowFragment;
  show: boolean;
  isFetching: boolean;
  onCancel: () => void;
  onSubmit: (update: PropertyDetailsRichTableRowInput) => void;
}> = ({ row, show, isFetching, onCancel, onSubmit }) => {
  const initialFocus = React.useRef(null);
  const labelId = React.useId();
  const [label, setLabel] = React.useState(row.label);
  const valueId = React.useId();
  const [extras, { addExtraAt, removeExtra, setExtra }] = useExtras(
    () => row.extras
  );
  const [value, setValue] = React.useState(row.value);
  const hideIfEmptyId = React.useId();
  const [hideIfEmpty, setHideIfEmpty] = React.useState(row.hideIfEmpty);
  const hiddenId = React.useId();
  const [hidden, setHidden] = React.useState(row.hidden);

  return (
    <ZenDialog
      icon={PencilIcon}
      onCancel={() => onCancel()}
      onSubmit={() =>
        onSubmit({
          label,
          value,
          extras: extras.map((extra) => extra.value),
          hidden,
          hideIfEmpty,
        })
      }
      submit="Update"
      title="Update row"
      show={show}
      state={isFetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
      initialFocus={initialFocus}
    >
      <div className="space-y-2">
        <div>
          <div>
            <label htmlFor={labelId}>Label</label>
          </div>
          <div>
            <input
              id={labelId}
              type="text"
              className="w-full"
              value={label}
              onChange={(e) => setLabel(e.target.value)}
            />
          </div>
        </div>
        <div>
          <div>
            <label htmlFor={valueId}>Value</label>
          </div>
          <div>
            <input
              id={valueId}
              ref={initialFocus}
              type="text"
              className="w-full"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
        </div>
        <div>
          <div>
            <label>Extras</label>
          </div>
          <div>
            <ExtrasList
              extras={extras}
              addExtraAt={addExtraAt}
              removeExtra={removeExtra}
              setExtra={setExtra}
            />
          </div>
        </div>
        <div>
          <div>
            <label htmlFor={hideIfEmptyId}>Hide if empty</label>
          </div>
          <div>
            <CommonSwitch
              enabled={hideIfEmpty}
              label="Hide if empty"
              toggle={() => setHideIfEmpty(!hideIfEmpty)}
            />
          </div>
        </div>
        <div>
          <div>
            <label htmlFor={hiddenId}>Hidden</label>
          </div>
          <div>
            <CommonSwitch
              enabled={hidden}
              label="Hidden"
              toggle={() => setHidden(!hidden)}
            />
          </div>
        </div>
      </div>
    </ZenDialog>
  );
};

const PropertyDetailsTableUpdateDialog: React.FC<{
  table: PropertyDetailsTableFragment;
  show: boolean;
  isFetching: boolean;
  onCancel: () => void;
  onSubmit: (update: PropertyDetailsTableInput) => void;
}> = ({ table, show, isFetching, onCancel, onSubmit }) => {
  const initialFocus = React.useRef(null);
  const titleId = React.useId();
  const styleId = React.useId();
  const [title, setTitle] = React.useState(table.title);
  const [hidden, setHidden] = React.useState(table.hidden);
  const [style, setStyle] = React.useState(table.style);

  return (
    <ZenDialog
      icon={PencilIcon}
      onCancel={() => onCancel()}
      onSubmit={() =>
        onSubmit({
          title,
          hidden,
          style,
        })
      }
      submit="Update"
      title="Update table"
      show={show}
      state={isFetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
      initialFocus={initialFocus}
    >
      <div className="space-y-2">
        <div>
          <div>
            <label htmlFor={titleId}>Title</label>
          </div>
          <div>
            <label htmlFor={titleId} className="text-xs text-gray-400">
              The title of this property details section
            </label>
          </div>
          <div>
            <input
              id={titleId}
              ref={initialFocus}
              type="text"
              className="w-full"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </div>
        <div>
          <div>
            <label>Hidden</label>
          </div>
          <div>
            <label className="text-xs text-gray-400">
              Whether to show this section
            </label>
          </div>
          <div>
            <CommonSwitch
              enabled={hidden}
              label="Hidden"
              toggle={() => setHidden(!hidden)}
            />
          </div>
        </div>
        <div>
          <div>
            <label htmlFor={styleId}>Style</label>
          </div>
          <div>
            <label htmlFor={styleId} className="text-xs text-gray-400">
              How the section looks
            </label>
          </div>
          <div>
            <select
              id={styleId}
              value={style}
              onChange={(e) =>
                setStyle(e.target.value as PropertyDetailsTableStyle)
              }
            >
              <option value={PropertyDetailsTableStyle.Normal}>Normal</option>
              <option value={PropertyDetailsTableStyle.Inset}>Inset</option>
            </select>
          </div>
        </div>
      </div>
    </ZenDialog>
  );
};

const PropertyDetailsRichTableUpdateDialog: React.FC<{
  richTable: PropertyDetailsRichTableFragment;
  show: boolean;
  isFetching: boolean;
  onCancel: () => void;
  onSubmit: (update: PropertyDetailsRichTableInput) => void;
}> = ({ richTable, show, isFetching, onCancel, onSubmit }) => {
  const initialFocus = React.useRef(null);
  const sectionHeaderId = React.useId();
  const sectionSubheaderId = React.useId();
  const titleId = React.useId();
  const [sectionHeader, setSectionHeader] = React.useState(
    richTable.sectionHeader
  );
  const [sectionSubheader, setSectionSubheader] = React.useState(
    richTable.sectionSubheader ?? ""
  );
  const [title, setTitle] = React.useState(richTable.title);
  const [extras, { addExtraAt, removeExtra, setExtra }] = useExtras(
    () => richTable.titleExtras
  );

  return (
    <ZenDialog
      icon={PencilIcon}
      onCancel={() => onCancel()}
      onSubmit={() =>
        onSubmit({
          sectionHeader: sectionHeader,
          sectionSubheader: sectionSubheader === "" ? null : sectionSubheader,
          title,
          titleExtras: extras.map((extra) => extra.value),
        })
      }
      submit="Update"
      title="Update rich table"
      show={show}
      state={isFetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
      initialFocus={initialFocus}
    >
      <div className="space-y-2">
        <div>
          <div>
            <label htmlFor={sectionHeaderId}>Section header</label>
          </div>
          <div>
            <label htmlFor={sectionHeaderId} className="text-xs text-gray-400">
              The header that is shown once at the top of the section.
            </label>
          </div>
          <div>
            <input
              id={sectionHeaderId}
              type="text"
              className="w-full"
              value={sectionHeader}
              onChange={(e) => setSectionHeader(e.target.value)}
            />
          </div>
        </div>
        <div>
          <div>
            <label htmlFor={sectionSubheaderId}>Section sub-header</label>
          </div>
          <div>
            <label
              htmlFor={sectionSubheaderId}
              className="text-xs text-gray-400"
            >
              The sub-header that is shown at most once at the top of the
              section.
            </label>
          </div>
          <div>
            <input
              id={sectionSubheaderId}
              type="text"
              className="w-full"
              value={sectionSubheader}
              onChange={(e) => setSectionSubheader(e.target.value)}
            />
          </div>
        </div>
        <div>
          <div>
            <label htmlFor={titleId}>Title</label>
          </div>
          <div>
            <label htmlFor={titleId} className="text-xs text-gray-400">
              A title for each particular resource, shown once per resource.
            </label>
          </div>
          <div>
            <input
              id={titleId}
              type="text"
              className="w-full"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </div>
        <div>
          <div>
            <label>Title extras</label>
          </div>
          <div>
            <label className="text-xs text-gray-400">
              Zero or more bits of information shown directly below the resource
              title without labels
            </label>
          </div>
          <div>
            <ExtrasList
              extras={extras}
              addExtraAt={addExtraAt}
              removeExtra={removeExtra}
              setExtra={setExtra}
            />
          </div>
        </div>
      </div>
    </ZenDialog>
  );
};

const PropertyDetailsRowRemoveDialog: React.FC<{
  show: boolean;
  isFetching: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}> = ({ show, isFetching, onCancel, onSubmit }) => {
  return (
    <ZenDialog
      icon={TrashIcon}
      onCancel={() => onCancel()}
      onSubmit={() => onSubmit()}
      submit="Remove"
      title="Remove row"
      show={show}
      state={isFetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
    >
      <div>Are you sure you want to remove this row?</div>
    </ZenDialog>
  );
};

const PropertyDetailsRowLabel: React.FC<{
  row: PropertyDetailsTableRowFragment | PropertyDetailsRichTableRowFragment;
}> = ({ row }) => {
  if (row.hidden) {
    return (
      <>
        <EyeSlashIcon className="w-3 h-3 inline-block text-gray-400" />{" "}
        <span className="text-gray-400">{row.label}</span>
      </>
    );
  } else {
    return <>{row.label}</>;
  }
};

const EditButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <button onClick={() => onClick()}>
      <PencilIcon className="w-4 h-4 text-gray-400 hover:text-black p-px bg-gray-100 border rounded-sm hover:bg-gray-200" />
    </button>
  );
};

const TrashButton: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <button onClick={() => onClick()}>
      <TrashIcon className="w-4 h-4 text-gray-400 hover:text-black p-px bg-gray-100 border rounded-sm hover:bg-gray-200" />
    </button>
  );
};

const AddReorderActions: React.FC<{
  onAddRow: () => void;
  onReorder?: () => void;
  reordering?: boolean;
}> = ({ onAddRow, onReorder, reordering }) => {
  return (
    <Menu as="div" className="relative inline-block text-left mt-4 sm:mt-0">
      <div>
        <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
          <span className="sr-only">Open options</span>
          <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-64 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
          <div className="py-1">
            {onAddRow && (
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => onAddRow()}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <PlusCircleIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Add row
                  </div>
                )}
              </Menu.Item>
            )}
            {onReorder && (
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => onReorder()}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <ChevronUpDownIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    {reordering ? "Stop reordering" : "Reorder"}
                  </div>
                )}
              </Menu.Item>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

const ExtrasList: React.FC<{
  extras: TaggedExtra[];
  addExtraAt: (index: number) => void;
  setExtra: (id: string, value: string) => void;
  removeExtra: (id: string) => void;
}> = ({ extras, addExtraAt, setExtra, removeExtra }) => {
  return extras.length ? (
    extras.map((extra, idx) => (
      <div key={extra.id}>
        <input
          type="text"
          value={extra.value}
          onChange={(e) => setExtra(extra.id, e.target.value)}
        />{" "}
        <button onClick={() => addExtraAt(idx + 1)}>
          <PlusCircleIcon className="w-4 h-4 text-gray-400" />
        </button>
        <button onClick={() => removeExtra(extra.id)}>
          <MinusCircleIcon className="w-4 h-4 text-gray-400" />
        </button>
      </div>
    ))
  ) : (
    <div>
      <span className="italic test-gray-400">No extras</span>{" "}
      <button onClick={() => addExtraAt(0)}>
        <PlusCircleIcon className="w-4 h-4 text-gray-400" />
      </button>
    </div>
  );
};

type TaggedExtra = { id: string; value: string };

function useExtras(initial?: () => string[]): [
  TaggedExtra[],
  {
    addExtraAt: (index: number) => void;
    setExtra: (id: string, value: string) => void;
    removeExtra: (id: string) => void;
  }
] {
  const [extras, setExtras] = React.useState<TaggedExtra[]>(() => {
    if (initial) {
      return initial().map((extra) => {
        return {
          id: randomId(),
          value: extra,
        };
      });
    } else {
      return [];
    }
  });
  const addExtraAt = React.useCallback(
    (index: number) => {
      const newExtra = {
        id: randomId(),
        value: "",
      };
      const newExtras = [
        ...extras.slice(0, index),
        newExtra,
        ...extras.slice(index),
      ];
      setExtras(newExtras);
    },
    [extras]
  );
  const removeExtra = React.useCallback(
    (id: string) => {
      const newExtras = extras.filter((extra) => extra.id != id);
      setExtras(newExtras);
    },
    [extras]
  );
  const setExtra = React.useCallback(
    (id: string, value: string) => {
      const newExtras = [...extras];
      const found = newExtras.find((val) => val.id == id);
      if (found) {
        found.value = value;
      }
      setExtras(newExtras);
    },
    [extras]
  );

  return [
    extras,
    {
      addExtraAt,
      removeExtra,
      setExtra,
    },
  ];
}

export default PropertyDetailsEditShow;
