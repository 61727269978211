import React from "react";
import Panel from "../../common/components/panel";
import {
  GroupFragment,
  SavedFilterState,
  useSavedFiltersQuery,
} from "../../graphql/generated";
import SavedFilterCard from "../../saved-filter/components/card";

const GroupSavedFilters: React.FC<{ group?: GroupFragment }> = ({ group }) => {
  const [{ data }] = useSavedFiltersQuery({
    variables: {
      groupId: group?.id ?? "",
      states: [SavedFilterState.Active, SavedFilterState.Archived],
    },
  });

  return (
    <Panel>
      <Panel.Title>
        Searches{" "}
        <span className="text-xs text-gray-500">
          {data?.savedFilters.length}
        </span>
      </Panel.Title>
      <Panel.Body>
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          {data?.savedFilters.map((filter) => (
            <SavedFilterCard filter={filter} key={filter.id} />
          ))}
        </div>
      </Panel.Body>
    </Panel>
  );
};

export default GroupSavedFilters;
