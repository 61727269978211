import React from "react";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import {
  SavedFilterInitialization,
  SavedFilterQuery,
} from "../../graphql/generated";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import Timestamp from "../../common/components/timestamp";
import { DescriptionList } from "../../common/components/descriptionlist";

function initialization(
  initialization: SavedFilterInitialization | undefined
): string {
  if (!initialization) {
    return "—";
  }
  switch (initialization) {
    case SavedFilterInitialization.ExcludeCurrentListings:
      return "Exclude current listings";
    case SavedFilterInitialization.IncludeCurrentListings:
      return "Include current listings";
    case SavedFilterInitialization.SelectedListings:
      return "Selected listings";
    case SavedFilterInitialization.LegacyUnknown:
      return "Legacy (unknown)";
  }
}

const SavedFilterSummary: React.FC<{
  filter?: SavedFilterQuery["savedFilter"];
}> = ({ filter }) => {
  return (
    <Panel>
      <Panel.Body summary>
        <DescriptionList>
          <DescriptionListItem
            title="Owner"
            value={filter?.owner?.name}
            link={`/accounts/${filter?.owner?.id}`}
          />
          <DescriptionListItem
            title="Group"
            value={filter?.group?.nameLong!}
            link={`/groups/${filter?.group?.id}`}
          />
          <DescriptionListItem title="Market" value={filter?.filter?.market} />
          <DescriptionListItem
            title="Initialization"
            value={initialization(filter?.initialization)}
          />
          <DescriptionListContainer title="Created">
            <Timestamp timestamp={filter?.createdAt} format="short" popover />
          </DescriptionListContainer>
          <DescriptionListContainer title="Archived">
            <Timestamp timestamp={filter?.archivedAt} format="short" popover />
          </DescriptionListContainer>
          <DescriptionListItem
            title="Previous Filter"
            value={filter?.parent?.id ? filter.parent.id : "—"}
            link={`/saved_filters/${filter?.parent?.id}`}
          />
        </DescriptionList>
      </Panel.Body>
    </Panel>
  );
};

export default SavedFilterSummary;
