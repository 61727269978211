import React from "react";
import DescriptionListContainer from "../../common/components/descriptionlistcontainer";
import DescriptionListItem from "../../common/components/descriptionlistitem";
import Panel from "../../common/components/panel";
import { StatusComponent } from "../../common/components/status";
import Timestamp from "../../common/components/timestamp";
import {
  ReprocessDataTaskDetailsFragment,
  ReprocessDataTaskStatus,
} from "../../graphql/generated";
import { formatDistance } from "date-fns";
import { Link } from "react-router-dom";

const ReprocessDataTaskSummary: React.FC<{
  task?: ReprocessDataTaskDetailsFragment;
}> = ({ task }) => {
  let percentCompleteDisplay = "—";
  let remainingDisplay = "—";
  let approximateRatePerMinute;
  let approximateEta;
  let totalRunTime;

  if (task) {
    let aboveTargetSchemaVersion = 0;
    let belowTargetSchemaVersion = 0;
    const targetSchemaVersion = task.targetSchemaVersion;

    for (const item of task.schemaVersionCounts) {
      if (item.schemaVersion == "total") {
        continue;
      } else if (item.schemaVersion == "older") {
        belowTargetSchemaVersion += item.count;
      } else if (item.schemaVersion >= targetSchemaVersion) {
        aboveTargetSchemaVersion += item.count;
      } else {
        belowTargetSchemaVersion += item.count;
      }
    }

    const total = aboveTargetSchemaVersion + belowTargetSchemaVersion;
    const ratioComplete = total > 0 ? aboveTargetSchemaVersion / total : 1;
    percentCompleteDisplay = ratioComplete.toLocaleString(undefined, {
      style: "percent",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
    remainingDisplay = belowTargetSchemaVersion.toLocaleString(undefined);

    if (task.totalRunTimeMilliseconds > 0) {
      const now = new Date();
      const future = new Date(now.valueOf());
      future.setMilliseconds(
        future.getMilliseconds() + task.totalRunTimeMilliseconds
      );
      totalRunTime = formatDistance(future, now);
    }

    approximateRatePerMinute =
      task.totalRunTimeMilliseconds === 0
        ? undefined
        : Math.floor(
            task.totalProcessed / (task.totalRunTimeMilliseconds / (1000 * 60))
          ).toLocaleString() + " listings/minute";

    const approximateRatePerSecond =
      task.totalRunTimeMilliseconds === 0
        ? undefined
        : task.totalProcessed / (task.totalRunTimeMilliseconds / 1000);

    const remainingSeconds =
      approximateRatePerSecond === undefined
        ? undefined
        : belowTargetSchemaVersion / approximateRatePerSecond;

    switch (task.status) {
      case ReprocessDataTaskStatus.Finished:
        approximateEta = "Finished";
        break;
      case ReprocessDataTaskStatus.Abandoned:
        approximateEta = "Abandoned";
        break;
      case ReprocessDataTaskStatus.Paused:
      case ReprocessDataTaskStatus.PausedForProblems:
      case ReprocessDataTaskStatus.Pausing:
      case ReprocessDataTaskStatus.WaitingOnCache:
      case ReprocessDataTaskStatus.Ready:
        if (remainingSeconds !== undefined) {
          const now = new Date();
          const future = new Date(now.valueOf());
          future.setSeconds(future.getSeconds() + remainingSeconds);
          approximateEta = formatDistance(future, now);
        }
        break;
      case ReprocessDataTaskStatus.Running:
        if (remainingSeconds !== undefined) {
          const now = new Date();
          const future = new Date(now.valueOf());
          future.setSeconds(future.getSeconds() + remainingSeconds);
          approximateEta = (
            <Timestamp format="long" popover timestamp={future.toISOString()} />
          );
        }
        break;
    }
  }

  return (
    <Panel>
      <Panel.Body summary>
        <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
          <DescriptionListItem title="ID" value={task?.id} />
          <DescriptionListContainer
            title="Status"
            info="Whether the task is running or has finished"
          >
            <StatusComponent entity={task} />
          </DescriptionListContainer>
          <DescriptionListItem
            title="MLS • Resource"
            value={`${task?.rootResource.mls.shortName} • ${task?.rootResource.name}`}
            link={`/resource_mapping/roots/${task?.rootResource.id}`}
          />
          <DescriptionListContainer title="Created at">
            <Timestamp format="long" timestamp={task?.createdAt} popover />
          </DescriptionListContainer>
          <DescriptionListContainer title="Modified at">
            <Timestamp format="long" timestamp={task?.modifiedAt} popover />
          </DescriptionListContainer>
          <DescriptionListContainer title="Finished at">
            <Timestamp format="long" timestamp={task?.finishedAt} popover />
          </DescriptionListContainer>
          <DescriptionListItem
            title="Target schema version"
            value={task?.targetSchemaVersion}
          />
          <DescriptionListItem
            title="Percent complete"
            info="The percentage of listings that are above the target schema version"
            value={percentCompleteDisplay}
          />
          <DescriptionListItem
            title="Remaining listings"
            info="The number of listings that are below the target schema version"
            value={remainingDisplay}
          />
          <DescriptionListItem
            title="Rate (approximate)"
            info="Approximately how many listings per minute are being processed"
            value={approximateRatePerMinute?.toLocaleString() ?? "—"}
          />
          <DescriptionListContainer
            title="ETA (approximate)"
            info="Approximately how long until the task is finished"
          >
            {approximateEta ?? "—"}
          </DescriptionListContainer>
          <DescriptionListItem
            title="Run time"
            info="The total amount of time that the reprocess has been running"
            value={totalRunTime}
          />
          <DescriptionListItem
            title="Number processed"
            info="The total number of listings processed by this task"
            value={task?.totalProcessed?.toLocaleString()}
          />
          <DescriptionListItem
            title="Problems"
            info="The total number of problems encountered while running this task"
            value={task?.totalProblems?.toLocaleString()}
          />
          <DescriptionListItem
            title="Skipped"
            info="The total number of entities skipped by this task"
            value={task?.totalSkipped?.toLocaleString()}
          />
          <DescriptionListContainer
            title="Problem threshold"
            info="The number of problems that need to occur for this task to auto-pause"
          >
            {task && task.problemThreshold !== null && (
              <>{task.problemsSinceLastStart.toLocaleString()} / </>
            )}
            {task && task.problemThreshold
              ? task.problemThreshold.toLocaleString()
              : "∞"}
          </DescriptionListContainer>
          <DescriptionListContainer
            title="Plan"
            info="The plan that will start this task when there is enough capacity, or that will start another task when this task finishes or pauses"
          >
            {task?.plan ? (
              <Link
                to={`/reprocess_data_plans/${task?.plan.id}`}
                className="text-blue-900 hover:underline"
              >
                {task.plan.title ?? task.plan.id}
              </Link>
            ) : (
              "—"
            )}
          </DescriptionListContainer>
          <DescriptionListContainer title="Filter" span={3}>
            {task?.filter && (
              <code>
                <pre>{JSON.stringify(task.filter, undefined, 2)}</pre>
              </code>
            )}
            {!task?.filter && "—"}
          </DescriptionListContainer>
        </dl>
      </Panel.Body>
    </Panel>
  );
};

export default ReprocessDataTaskSummary;
