import { XCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import { AgentFragment } from "../../graphql/generated";
import { ZenDialog } from "../../common/components/zen-dialog";

export const UnenrollAgentInternalListingBucketDialog: React.FC<{
  agent?: AgentFragment;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
}> = ({ agent, isOpen, onClose, onSubmit }) => {
  if (!agent) {
    return <></>;
  }

  return (
    <ZenDialog
      show={isOpen}
      title="Unenroll Agent"
      icon={XCircleIcon}
      submit="Unenroll"
      onSubmit={() => onSubmit()}
      onCancel={onClose}
    >
      <div className="mt-1">
        Are you sure you want to unenroll <strong>{agent.name}</strong>?
      </div>
    </ZenDialog>
  );
};
