import { UserGroupIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../common/utils/classnames";
import { useSavedFilterQuery } from "../../graphql/generated";
import Timestamp from "../../common/components/timestamp";

const SavedFilterHistoryItem: React.FC<{ id: string; first?: boolean }> = ({
  id,
}) => {
  const [{ data }] = useSavedFilterQuery({ variables: { id } });

  return (
    <>
      <li>
        <div className="relative pb-8">
          {data?.savedFilter.parent?.id ? (
            <span
              className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
              aria-hidden="true"
            />
          ) : null}
          <div className="relative flex space-x-3">
            <div>
              <span
                className={classNames(
                  data?.savedFilter.archivedAt ? "bg-red-400" : "bg-green-400",
                  "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                )}
              >
                <UserGroupIcon
                  className="h-5 w-5 text-white"
                  aria-hidden="true"
                />
              </span>
            </div>
            <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
              <div>
                <p className="text-sm text-gray-500">
                  {data?.savedFilter.owner.name}{" "}
                  <Link
                    to={`/saved_filters/${data?.savedFilter.id}`}
                    className="font-medium text-gray-900"
                  >
                    {data?.savedFilter.name}
                  </Link>
                </p>
              </div>
              <div className="text-right text-sm whitespace-nowrap text-gray-500">
                Archived:{" "}
                <Timestamp
                  timestamp={data?.savedFilter.archivedAt}
                  format="long"
                  popover
                />
              </div>
              <div className="text-right text-sm whitespace-nowrap text-gray-500">
                Created:{" "}
                <Timestamp
                  timestamp={data?.savedFilter.createdAt}
                  format="long"
                  popover
                />
              </div>
            </div>
          </div>
        </div>
      </li>
      {data?.savedFilter.parent && (
        <SavedFilterHistoryItem id={data?.savedFilter.parent.id} />
      )}
    </>
  );
};

export default SavedFilterHistoryItem;
