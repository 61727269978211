import { Dialog, Menu, RadioGroup, Transition } from "@headlessui/react";
import {
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  UserPlusIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../common/utils/classnames";
import { SearchContext } from "../../common/context/search";
import {
  AgentsDocument,
  InternalListingBucketFragment,
  useAgentsQuery,
  useDeleteInternalListingBucketMutation,
  useInternalListingBucketEnrollAgentMutation,
} from "../../graphql/generated";
import { DeleteInternalListingBucketDialog } from "./delete-dialog";
import { NotificationContext } from "../../common/context/notification";
import { useClient } from "urql";

const InternalListingBucketItemActions: React.FC<{
  bucket?: InternalListingBucketFragment;
}> = ({ bucket }) => {
  const { notifier } = React.useContext(NotificationContext);
  const [deleteIsOpen, setDeleteIsOpen] = React.useState(false);

  const graphqlClient = useClient();
  const [manuallyEnrollOpen, setManuallyEnrollOpen] = React.useState(false);
  const { search, setSearch, searchBounced } = React.useContext(SearchContext);
  const [loading, setLoading] = React.useState(false);
  const [selected, setSelected] = React.useState<string>();
  const [{ data }] = useAgentsQuery({
    variables: {
      first: 20,
      search: searchBounced,
    },
  });
  const fetchMore = React.useCallback(async () => {
    if (data?.agents.pageInfo.hasNextPage) {
      setLoading(true);
      await graphqlClient
        .query(AgentsDocument, {
          first: 20,
          after: data.agents.pageInfo.endCursor,
          search: searchBounced,
        })
        .toPromise();
      setLoading(false);
    }
  }, [data, graphqlClient, searchBounced]);
  const [{ fetching }, mutation] = useInternalListingBucketEnrollAgentMutation();
  const manuallyEnrollAgent = React.useCallback(() => {
    if (!fetching && selected) {
      mutation({ internalListingBucketId: bucket?.id || "", agentId: selected });
    }
  }, [bucket, fetching, mutation, selected]);

  const [, deleteBucketMutation] = useDeleteInternalListingBucketMutation();

  const deleteBucket = React.useCallback(async () => {
    await deleteBucketMutation({ id: bucket?.id || "" }).then(
      notifier.notifyGraphql("Bucket deleted")
    );
    setDeleteIsOpen(false);
  }, [bucket?.id, deleteBucketMutation, setDeleteIsOpen]);

  return (
    <>
      <Menu as="div" className="relative inline-block text-left mt-4 sm:mt-0">
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <Link
                    to={`/internal_listing_buckets/${bucket?.id}/edit`}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <PencilIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Edit...
                  </Link>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setManuallyEnrollOpen(!manuallyEnrollOpen)}
                  >
                    <UserPlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Manually Enroll Agent
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={() => setDeleteIsOpen(true)}
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                  >
                    <XCircleIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Delete...
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>

      <DeleteInternalListingBucketDialog
        bucket={bucket}
        isOpen={deleteIsOpen}
        onClose={() => setDeleteIsOpen(false)}
        onSubmit={() => deleteBucket()}
      />

      <Transition.Root show={manuallyEnrollOpen} as={React.Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden z-50"
          onClose={setManuallyEnrollOpen}
        >
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={React.Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-none">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-lg font-medium text-gray-900">
                        Link Agent To Account
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => setManuallyEnrollOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 relative flex-1  flex flex-col overflow-scroll">
                    <div className="px-4 sm:px-6">
                      <div className="mt-1 relative rounded-md shadow-sm flex-1">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <MagnifyingGlassIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          type="search"
                          name="search"
                          id="search"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                          placeholder={`Search for agents`}
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="px-4 sm:px-6 py-4 sm:py-6 mt-4 grow overflow-scroll">
                      <RadioGroup value={selected} onChange={setSelected}>
                        <RadioGroup.Label className="sr-only">
                          Agent
                        </RadioGroup.Label>
                        <div className="space-y-2">
                          {data?.agents.edges?.map((agent) => (
                            <RadioGroup.Option
                              key={agent?.node.id}
                              value={agent?.node.id}
                              className={({ checked, active }) =>
                                classNames(
                                  checked
                                    ? "border-transparent"
                                    : "border-gray-300",
                                  active ? "ring-2 ring-indigo-500" : "",
                                  "relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer sm:flex sm:justify-between focus:outline-none"
                                )
                              }
                            >
                              {({ active, checked }) => (
                                <>
                                  <div className="flex items-center">
                                    <div className="text-sm">
                                      <RadioGroup.Label
                                        as="div"
                                        className="font-medium text-gray-900"
                                      >
                                        {agent?.node.name}
                                        <p className="sm:inline text-xs text-gray-500">
                                          &middot;{" "}
                                          {agent?.node.account?.name ??
                                            "No Current Account"}
                                        </p>
                                        <p className="sm:inline text-xs text-gray-500">
                                          &middot; {agent?.node.mlsId}{" "}
                                          &middot; {agent?.node.mls.shortName}{" "}
                                        </p>
                                      </RadioGroup.Label>
                                      <RadioGroup.Description
                                        as="div"
                                        className="text-gray-500"
                                      >
                                        <p className="sm:inline">
                                          {agent?.node.office?.name}
                                        </p>{" "}
                                        <span
                                          className="hidden sm:inline sm:mx-1"
                                          aria-hidden="true"
                                        >
                                          &middot;
                                        </span>{" "}
                                        <p className="sm:inline">
                                          {agent?.node.office?.address?.city},{" "}
                                          {agent?.node.office?.address?.state}
                                        </p>
                                      </RadioGroup.Description>
                                    </div>
                                  </div>
                                  <div
                                    className={classNames(
                                      active ? "border" : "border-2",
                                      checked
                                        ? "border-indigo-500"
                                        : "border-transparent",
                                      "absolute -inset-px rounded-lg pointer-events-none"
                                    )}
                                    aria-hidden="true"
                                  />
                                </>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>

                      <div className="min-w-full divide-y divide-gray-200">
                        <div className="p-4">
                          <button
                            className={classNames(
                              loading
                                ? "bg-gray-500 text-white"
                                : "bg-zenlist-500 hover:bg-zenlist-700 text-white",
                              "font-bold py-2 px-4 rounded"
                            )}
                            onClick={() => fetchMore()}
                            disabled={loading}
                          >
                            Fetch More
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="pt-5 px-4 sm:px-6">
                      <div className="flex justify-end">
                        <button
                          onClick={() => setManuallyEnrollOpen(false)}
                          type="button"
                          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Cancel
                        </button>
                        <button
                          onClick={manuallyEnrollAgent}
                          type="submit"
                          className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Link
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default InternalListingBucketItemActions;
