import React from "react";
import {
  RmFieldDetailsFragment,
  RmMappingSourceFragment,
  RmMappingSourceInput,
  useRmFieldAddBooleanLookupMappingMutation,
  useRmFieldAddBooleanLookupPriorityMappingMutation,
  useRmFieldAddRuleMappingMutation,
} from "../../../graphql/generated";
import { NotificationContext } from "../../../common/context/notification";
import { Menu, Transition } from "@headlessui/react";
import {
  ChevronUpDownIcon,
  EllipsisVerticalIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../../../common/utils/classnames";
import {
  ZenDialog,
  ZenDialogState,
} from "../../../common/components/zen-dialog";
import { EditableSource, mappingSourceToInput } from "../mapping-source";

const BooleanMappingsActions: React.FC<{
  field?: RmFieldDetailsFragment;
  isReordering?: boolean;
  onStartReordering?: () => void;
}> = ({ field, isReordering, onStartReordering }) => {
  if (!field) {
    return <></>;
  }

  const { notifier } = React.useContext(NotificationContext);
  const [addBooleanLookupDialogIsVisible, setAddBooleanLookupDialogIsVisible] =
    React.useState(false);
  const [
    addBooleanLookupPriorityDialogIsVisible,
    setAddBooleanLookupPriorityDialogIsVisible,
  ] = React.useState(false);
  const [addRuleDialogIsVisible, setAddRuleDialogIsVisible] =
    React.useState(false);

  const [{ fetching: addBooleanLookupFetching }, booleanLookupMutation] =
    useRmFieldAddBooleanLookupMappingMutation();
  const [
    { fetching: addBooleanLookupPriorityFetching },
    booleanLookupPriorityMutation,
  ] = useRmFieldAddBooleanLookupPriorityMappingMutation();

  const addBooleanLookupCallback = React.useCallback(
    async (source: RmMappingSourceInput) => {
      await booleanLookupMutation({
        fieldId: field.id,
        source,
      }).then(notifier.notifyGraphql("Mapping created"));
      setAddBooleanLookupDialogIsVisible(false);
    },
    [booleanLookupMutation, field, setAddBooleanLookupDialogIsVisible]
  );

  const addBooleanLookupPriorityCallback = React.useCallback(
    async (source: RmMappingSourceInput) => {
      await booleanLookupPriorityMutation({
        fieldId: field.id,
        source,
      }).then(notifier.notifyGraphql("Mapping created"));
      setAddBooleanLookupPriorityDialogIsVisible(false);
    },
    [booleanLookupMutation, field, setAddBooleanLookupDialogIsVisible]
  );

  const [{ fetching: addRuleFetching }, addRuleMutation] =
    useRmFieldAddRuleMappingMutation();

  const addRuleCallback = React.useCallback(async () => {
    await addRuleMutation({
      fieldId: field.id,
      isLocal: false,
    }).then(notifier.notifyGraphql("Mapping created"));
    setAddRuleDialogIsVisible(false);
  }, [addRuleMutation, field, setAddRuleDialogIsVisible]);

  return (
    <>
      <Menu
        as="div"
        className="relative inline-block text-left mt-4 sm:mt-0 z-50"
      >
        <div>
          <Menu.Button className="bg-gray-100 rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>

        <Transition
          as={React.Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setAddBooleanLookupDialogIsVisible(true)}
                  >
                    <PlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Add boolean lookup mapping...
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() =>
                      setAddBooleanLookupPriorityDialogIsVisible(true)
                    }
                  >
                    <PlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Add boolean lookup priority mapping...
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    className={classNames(
                      active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm cursor-pointer"
                    )}
                    onClick={() => setAddRuleDialogIsVisible(true)}
                  >
                    <PlusIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Add rule mapping...
                  </div>
                )}
              </Menu.Item>
              <Menu.Item disabled={isReordering}>
                {({ active, disabled }) => (
                  <div
                    className={classNames(
                      active
                        ? "bg-gray-100 text-gray-900 cursor-pointer"
                        : disabled
                        ? "text-gray-400"
                        : "text-gray-700",
                      "group flex items-center px-4 py-2 text-sm"
                    )}
                    onClick={() => onStartReordering && onStartReordering()}
                  >
                    <ChevronUpDownIcon
                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Reorder
                  </div>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <AddBooleanLookupMappingDialog
        show={addBooleanLookupDialogIsVisible}
        onSubmit={addBooleanLookupCallback}
        onCancel={() => setAddBooleanLookupDialogIsVisible(false)}
        fetching={addBooleanLookupFetching}
      />
      <AddBooleanLookupPriorityMappingDialog
        show={addBooleanLookupPriorityDialogIsVisible}
        onSubmit={addBooleanLookupPriorityCallback}
        onCancel={() => setAddBooleanLookupPriorityDialogIsVisible(false)}
        fetching={addBooleanLookupPriorityFetching}
      />
      <AddRuleMappingDialog
        show={addRuleDialogIsVisible}
        onSubmit={addRuleCallback}
        onCancel={() => setAddRuleDialogIsVisible(false)}
        fetching={addRuleFetching}
      />
    </>
  );
};

const AddBooleanLookupMappingDialog: React.FC<{
  show?: boolean;
  onSubmit: (source: RmMappingSourceInput) => void;
  onCancel: () => void;
  fetching?: boolean;
}> = ({ show, onSubmit, onCancel, fetching }) => {
  const [source, setSource] = React.useState<RmMappingSourceFragment>();
  const valueId = React.useId();
  const isValid = !!source;
  return (
    <ZenDialog
      show={show}
      title="Add boolean lookup mapping"
      icon={PlusIcon}
      submit="Add"
      onSubmit={() => source && onSubmit(mappingSourceToInput(source))}
      onCancel={onCancel}
      state={
        fetching
          ? ZenDialogState.Submitting
          : isValid
          ? ZenDialogState.Displaying
          : ZenDialogState.Invalid
      }
    >
      <div className="mt-1">
        <p>
          A boolean lookup mapping maps values from the source field to either
          true or false.
        </p>
      </div>
      <div className="mt-1">
        <div>
          <label htmlFor={valueId}>Source field</label>
        </div>
        <div>
          {" "}
          <EditableSource
            source={source}
            onSubmit={(value) => setSource(value)}
          />
        </div>
      </div>
    </ZenDialog>
  );
};

const AddBooleanLookupPriorityMappingDialog: React.FC<{
  show?: boolean;
  onSubmit: (source: RmMappingSourceInput) => void;
  onCancel: () => void;
  fetching?: boolean;
}> = ({ show, onSubmit, onCancel, fetching }) => {
  const [source, setSource] = React.useState<RmMappingSourceFragment>();
  const valueId = React.useId();
  const isValid = !!source;
  return (
    <ZenDialog
      show={show}
      title="Add boolean lookup priority mapping"
      icon={PlusIcon}
      submit="Add"
      onSubmit={() => source && onSubmit(mappingSourceToInput(source))}
      onCancel={onCancel}
      state={
        fetching
          ? ZenDialogState.Submitting
          : isValid
          ? ZenDialogState.Displaying
          : ZenDialogState.Invalid
      }
    >
      <div className="mt-1">
        <p>
          A boolean lookup priority mapping maps values from the source field to
          either true or false.
        </p>
      </div>
      <div className="mt-1">
        <div>
          <label htmlFor={valueId}>Source field</label>
        </div>
        <div>
          {" "}
          <EditableSource
            source={source}
            onSubmit={(value) => setSource(value)}
          />
        </div>
      </div>
    </ZenDialog>
  );
};

const AddRuleMappingDialog: React.FC<{
  show?: boolean;
  onSubmit: () => void;
  onCancel: () => void;
  fetching?: boolean;
}> = ({ show, onSubmit, onCancel, fetching }) => {
  return (
    <ZenDialog
      show={show}
      title="Add rule mapping"
      icon={PlusIcon}
      submit="Add"
      onSubmit={() => onSubmit()}
      onCancel={onCancel}
      state={fetching ? ZenDialogState.Submitting : ZenDialogState.Displaying}
    >
      <div className="mt-1">
        <p>
          A rule mapping evaluates a RESO-standard RETS expression and maps the
          result of the expression onto the destination field.
        </p>
      </div>
    </ZenDialog>
  );
};

export default BooleanMappingsActions;
