import React from "react";
import { Link } from "react-router-dom";
import { AgentFragment, InternalListingBucketFragment, useInternalListingBucketUnenrollAgentMutation } from "../../graphql/generated";
import AgentPic from "../../agent/components/pic";
import { UnenrollAgentInternalListingBucketDialog } from "./unenroll-dialog";
import { NotificationContext } from "../../common/context/notification";

const AgentCard: React.FC<{ agent?: AgentFragment, bucket?: InternalListingBucketFragment, }> = ({ agent, bucket }) => {
  const { notifier } = React.useContext(NotificationContext);
  const [unenrollIsOpen, setUnenrollIsOpen] = React.useState(false);
  const [, unenrollAgentMutation] = useInternalListingBucketUnenrollAgentMutation();

  const unenrollAgent = React.useCallback(async () => {
    await unenrollAgentMutation({ agentId: agent?.id || "", internalListingBucketId: bucket?.id || ""}).then(
      notifier.notifyGraphql("Bucket deleted")
    );
    setUnenrollIsOpen(false);
  }, [bucket?.id, unenrollAgentMutation, setUnenrollIsOpen]);

  return (
    <>
      <div className="relative rounded-lg border border-gray-300 bg-white px-6 py-3 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
        <div className="flex-shrink-0">
          <AgentPic agent={agent} />
        </div>
        <div className="flex-1 min-w-0">
          <Link to={`/agents/${agent?.id}`} className="focus:outline-none">
            <p className="text-sm font-medium text-gray-900">
              {agent?.name.trim() === "" ? "Empty Name" : agent?.name} ·{" "}
              {agent?.mls?.shortName}: {agent?.mlsId}
            </p>
            <p className="text-sm text-gray-500 truncate">
              {agent?.office?.name}
            </p>
            <p className="text-sm text-gray-500 truncate">
              {agent?.office?.address?.city}, {agent?.office?.address?.state}
            </p>
          </Link>
        </div>
        <div className="flex-shrink-0">
          <button type="button" onClick={() => setUnenrollIsOpen(true)} className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">Unenroll</button>
        </div>
      </div>
      <UnenrollAgentInternalListingBucketDialog
        agent={agent}
        isOpen={unenrollIsOpen}
        onClose={() => setUnenrollIsOpen(false)}
        onSubmit={() => unenrollAgent()}
      />
  </>);
};

export default AgentCard;
