import {
  RmMappingDetailsFragment,
  RmProblemConnectionFragment,
} from "../../../graphql/generated";
import RMBooleanLookupMappingTypeControl from "./RMBooleanLookupMappingTypeControl";
import RMBooleanLookupPriorityMappingTypeControl from "./RMBooleanLookupPriorityMappingTypeControl";
import RMDateBasicMappingTypeControl from "./RMDateBasicMappingTypeControl";
import RMKeyBasicMappingTypeControl from "./RMKeyBasicMappingTypeControl";
import RMLookupBasicMappingTypeControl from "./RMLookupBasicMappingTypeControl";
import RMLookupConstantMappingTypeControl from "./RMLookupConstantMappingTypeControl";
import RMLookupPriorityMappingTypeControl from "./RMLookupPriorityMappingTypeControl";
import RMLookupTwoSourceMappingTypeControl from "./RMLookupTwoSourceMappingTypeControl";
import RMLookupTwoSourcePriorityMappingTypeControl from "./RMLookupTwoSourcePriorityMappingTypeControl";
import RMNumberBasicMappingTypeControl from "./RMNumberBasicMappingTypeControl";
import RMResourceBasicMappingTypeControl from "./RMResourceBasicMappingTypeControl";
import RMRuleMappingTypeControl from "./RMRuleMappingTypeControl";
import RMTextBasicMappingTypeControl from "./RMTextBasicMappingTypeControl";
import RMTextFlattenMappingTypeControl from "./RMTextFlattenMappingTypeControl";
import RMTimestampBasicMappingTypeControl from "./RMTimestampBasicMappingTypeControl";
import RMUnsupportedMappingTypeControl from "./RMUnsupportedMappingTypeControl";

const RMMappingTypeControl: React.FC<{
  mapping: RmMappingDetailsFragment;
  problems?: RmProblemConnectionFragment;
}> = ({ mapping, problems }) => {
  switch (mapping.type.__typename) {
    case "RMBooleanLookupMappingType":
      return (
        <RMBooleanLookupMappingTypeControl
          mapping={mapping}
          mappingType={mapping.type}
          problems={problems}
        />
      );
    case "RMBooleanLookupPriorityMappingType":
      return (
        <RMBooleanLookupPriorityMappingTypeControl
          mapping={mapping}
          mappingType={mapping.type}
          problems={problems}
        />
      );
    case "RMDateBasicMappingType":
      return (
        <RMDateBasicMappingTypeControl
          mapping={mapping}
          mappingType={mapping.type}
        />
      );
    case "RMLookupBasicMappingType":
      return (
        <RMLookupBasicMappingTypeControl
          mapping={mapping}
          mappingType={mapping.type}
          problems={problems}
        />
      );
    case "RMLookupTwoSourceMappingType":
      return (
        <RMLookupTwoSourceMappingTypeControl
          mapping={mapping}
          mappingType={mapping.type}
          problems={problems}
        />
      );
    case "RMLookupPriorityMappingType":
      return (
        <RMLookupPriorityMappingTypeControl
          mapping={mapping}
          mappingType={mapping.type}
          problems={problems}
        />
      );
    case "RMLookupConstantMappingType":
      return (
        <RMLookupConstantMappingTypeControl
          mapping={mapping}
          mappingType={mapping.type}
          problems={problems}
        />
      );
    case "RMLookupTwoSourcePriorityMappingType":
      return (
        <RMLookupTwoSourcePriorityMappingTypeControl
          mapping={mapping}
          mappingType={mapping.type}
          problems={problems}
        />
      );
    case "RMNumberBasicMappingType":
      return (
        <RMNumberBasicMappingTypeControl
          mapping={mapping}
          mappingType={mapping.type}
        />
      );
    case "RMRuleMappingType":
      return (
        <RMRuleMappingTypeControl
          mapping={mapping}
          mappingType={mapping.type}
        />
      );
    case "RMTextBasicMappingType":
      return (
        <RMTextBasicMappingTypeControl
          mapping={mapping}
          mappingType={mapping.type}
        />
      );
    case "RMTextFlattenMappingType":
      return (
        <RMTextFlattenMappingTypeControl
          mapping={mapping}
          mappingType={mapping.type}
        />
      );
    case "RMKeyBasicMappingType":
      return (
        <RMKeyBasicMappingTypeControl
          mapping={mapping}
          mappingType={mapping.type}
        />
      );
    case "RMTimestampBasicMappingType":
      return (
        <RMTimestampBasicMappingTypeControl
          mapping={mapping}
          mappingType={mapping.type}
        />
      );
    case "RMResourceBasicMappingType":
      return (
        <RMResourceBasicMappingTypeControl
          mapping={mapping}
          mappingType={mapping.type}
        />
      );
    case "RMTodoMappingType":
      return <RMUnsupportedMappingTypeControl mapping={mapping} />;
  }
};

export default RMMappingTypeControl;
